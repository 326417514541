import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExceptionType, ExceptionModule } from '@delon/abc/exception';

@Component({
  selector: 'app-exception',
  template: `
    <exception [type]="type" style="min-height: 500px; height: 80%;" [img]="'assets/maintenance/undraw_clean_up_ucm0.svg'" />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ExceptionModule]
})
export class ExceptionComponent {
  get type(): ExceptionType {
    return this.route.snapshot.data['type'];
  }

  constructor(private route: ActivatedRoute) {}
}
