import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { merge, of } from 'rxjs';
import { map, catchError, tap, switchMap, concatMap } from 'rxjs/operators';

import { AuthService } from 'src/app/shared/services/auth.service';

import { MyDataSourceActions, NotificationConfirmApiActions, NotificationConfirmUiActions } from './notification-confirm.actions';
import { NotificationConfirmService } from './notification-confirm.service';

@Injectable()
export class NotificationConfirmEffects {
  findAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationConfirmUiActions.findAllTriggered),
      switchMap(action => {
        return this.notificationConfirmSrv
          .findMany({
            ...action.filter,
            userId: { eq: this.auth.currentUser.email }
          })
          .pipe(
            map(({ data, errors }) =>
              data
                ? NotificationConfirmApiActions.findAllSucceeded({ items: data.notificationConfirms.edges.map(e => e.node) })
                : NotificationConfirmApiActions.requestFailed({ errors: errors ?? [] })
            ),
            catchError(err => of(NotificationConfirmApiActions.requestFailed({ errors: [err] })))
          );
      })
    );
  });

  countUnread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationConfirmUiActions.findAllTriggered),
      switchMap(({ filter }) => {
        return this.notificationConfirmSrv
          .findMany({ ...filter, confirmed: { is: false }, userId: { eq: this.auth.currentUser.email } })
          .pipe(
            map(({ data, errors }) =>
              data
                ? NotificationConfirmApiActions.countUnreadSucceeded({ count: data.notificationConfirms.totalCount })
                : NotificationConfirmApiActions.requestFailed({ errors: errors ?? [] })
            ),
            catchError(err => of(NotificationConfirmApiActions.requestFailed({ errors: [err] })))
          );
      })
    );
  });

  observe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationConfirmUiActions.findAllTriggered),
      concatMap(({ filter }) => {
        return merge(
          this.notificationConfirmSrv.observeUpdated({ ...filter, userId: { eq: this.auth.currentUser.email } }),
          this.notificationConfirmSrv.observeCreated({ ...filter, userId: { eq: this.auth.currentUser.email } })
        ).pipe(map(updated => NotificationConfirmApiActions.updatedOne({ item: updated })));
      })
    );
  });

  updateOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationConfirmUiActions.updateOneTriggered),
      concatMap(p =>
        this.notificationConfirmSrv
          .update({
            id: p.id,
            update: p.update
          })
          .pipe(
            map(({ data, errors }) =>
              data
                ? NotificationConfirmApiActions.updateOneSucceeded({ id: data.updateOneNotificationConfirm.id })
                : NotificationConfirmApiActions.requestFailed({ errors: errors ?? [] })
            ),
            catchError(err => of(NotificationConfirmApiActions.requestFailed({ errors: [err] })))
          )
      )
    );
  });

  markAsREad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationConfirmUiActions.markAsReadTriggered),
      concatMap(p =>
        this.notificationConfirmSrv.markAsRead().pipe(
          map(({ data, errors }) =>
            data
              ? NotificationConfirmApiActions.markAsReadSucceeded()
              : NotificationConfirmApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(NotificationConfirmApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  onCreateOrUpdateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotificationConfirmApiActions.updateOneSucceeded)
        // tap(({ id }) => {
        //   this.msgSrv.info(this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.UPDATE.success'));
        //   this.router.navigate(['/admin/notifications', id]);
        // })
      );
    },
    { dispatch: false }
  );

  onError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotificationConfirmApiActions.requestFailed),
        tap(({ errors }) => {
          console.error(errors);
          this.msgSrv.error(this.translateSrv.instant('COMMON.ERROR.unexpected'));
        })
      );
    },
    { dispatch: false }
  );

  dataSourceFetchedPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MyDataSourceActions.fetchedPage),
      map(({ items }) => {
        return NotificationConfirmApiActions.findAllSucceeded({ items });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private notificationConfirmSrv: NotificationConfirmService,
    private msgSrv: NzMessageService,
    private translateSrv: TranslateService,
    private router: Router,
    private auth: AuthService,
    private store: Store
  ) {}
}
