@import '@delon/theme/theme-default.less';

:host ::ng-deep .ant-badge {
  position: inherit !important;
  display: block;
  float: right;
}

a.sidebar-nav__item-disabled {
  pointer-events: none;
}

a.sidebar-nav__item-link {border-left: 3px solid transparent;}

a.sidebar-nav__item-active {
  border-color: @alain-default-aside-nav-selected-text-color;
}