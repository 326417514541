import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Injector, inject } from '@angular/core';
import { DA_SERVICE_TOKEN } from '@delon/auth';
import { IGNORE_BASE_URL } from '@delon/theme';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthService } from 'src/app/shared/services/auth.service';

import { ReThrowHttpError, checkStatus, getAdditionalHeaders, goTo, toLogin } from './helper';
import { REFRESH_URL, tryRefreshToken } from './refresh-token';

function handleData(injector: Injector, ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandlerFn): Observable<any> {
  checkStatus(injector, ev);
  // Business processing: some common operations
  switch (ev.status) {
    case 200:
      break;
    case 401:
      if (
        (environment.api.refreshTokenEnabled && environment.api.refreshTokenType === 're-request') ||
        injector.get(AuthService).currentTokenPair$.value?.refresh_token
      ) {
        return tryRefreshToken(injector, ev, req, next);
      }
      // We have to make the check looser because of org switches that append the ?organization=xxx to this request
      if (ev.url?.includes(REFRESH_URL) || ev.url?.includes(injector.get(DA_SERVICE_TOKEN).login_url!)) {
        // If I am already on a refresh mission and this fails, I can't do anything else.
        // This is relevant, because if I don't check this we could enter an infinite refresh loop.
        return throwError(() => ev);
      }
      toLogin(injector);
      break;
    case 403:
    case 404:
    case 500:
      goTo(injector, `/exception/${ev.status}?url=${req.urlWithParams}`);
      break;
    case 502:
      // Let's not show these errors to the user, if there are bad gateway in data or frontend-data, this will caught before
      console.warn(`Skipping bad gateway error in request: ${JSON.stringify(ev)}`);
      return of({});
    default:
      if (ev instanceof HttpErrorResponse) {
        console.warn(
          'Unknown errors are mostly caused by the backend not supporting cross-domain CORS or invalid configuration. Please refer to https://ng-alain.com/docs/server to solve cross-domain issues.',
          ev
        );
      }
      break;
  }
  if (ev instanceof HttpErrorResponse) {
    return throwError(() => ev);
  } else if ((ev as unknown as ReThrowHttpError)._throw === true) {
    return throwError(() => (ev as unknown as ReThrowHttpError).body);
  } else {
    return of(ev);
  }
}

export const defaultInterceptor: HttpInterceptorFn = (req, next) => {
  // Unify and add server prefix
  let url = req.url;
  if (!req.context.get(IGNORE_BASE_URL) && !url.startsWith('https://') && !url.startsWith('http://')) {
    const { baseUrl } = environment.api;
    url = baseUrl + (baseUrl.endsWith('/') && url.startsWith('/') ? url.substring(1) : url);
  }
  const newReq = req.clone({ url, setHeaders: getAdditionalHeaders(req.headers) });
  const injector = inject(Injector);

  return next(newReq).pipe(
    mergeMap(ev => {
      // Allows unified handling of request errors
      if (ev instanceof HttpResponseBase) {
        return handleData(injector, ev, newReq, next);
      }
      // If everything is normal, follow-up operations
      return of(ev);
    })
    // catchError((err: HttpErrorResponse) => handleData(injector, err, newReq, next))
  );
};
