import { NgClass, AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit, TemplateRef } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, Event, PRIMARY_OUTLET, RouterOutlet } from '@angular/router';
import { NoticeIconModule } from '@delon/abc/notice-icon';
import { ACLService } from '@delon/acl';
import { LayoutDefaultOptions, LayoutDefaultModule } from '@delon/theme/layout-default';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, switchMap, take, timer } from 'rxjs';

import { StartupService } from '@core';
import { environment } from '@env/environment';
import { FreshdeskService } from 'src/app/freshdesk.service';
import { Organization, DocumentScope } from 'src/app/graphql/frontend-data-graphql';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OrganizationUiActions } from 'src/app/state/organization/organization.actions';
import { selectManyOrganizations } from 'src/app/state/organization/organization.reducer';
import { SettingsStore } from 'src/app/state/settings/settings.store';

import { LayoutNavComponent } from './navigation/navigation.component';
import { HeaderNotifyComponent } from './widgets/header-notify.component';
import { HelperService } from './widgets/helper/helper.service';
import { HeaderSearchComponent } from './widgets/search.component';
import { HeaderUserComponent } from './widgets/user.component';
import packageVersion from '../../../../package.json';
import { TranslateScopePipe } from '../../shared/pipes/translate-scope.pipe';

@Component({
  selector: 'layout-basic',
  template: `
    <layout-default [options]="options" [nav]="nav" [content]="contentTpl" [customError]="null">
      <layout-default-header-item direction="left">
        <h3 style="position: relative; top: 3px; color: white">{{ environment['platform_label'] }}</h3>
      </layout-default-header-item>

      @if (authService.currentTokenPair$ | async; as currentToken) {
        <layout-default-header-item>
          <nz-spin style="width:24px" nzSimple [nzSpinning]="currentToken?.refreshInProgress" [nzIndicator]="indicatorTemplate">
            <ng-template #indicatorTemplate>
              <span style="color:white" nz-icon nzType="loading"></span>
            </ng-template>
          </nz-spin>
        </layout-default-header-item>
        <layout-default-header-item direction="right">
          @if (environment.platform_warning) {
            <nz-alert style="display:inline-block;margin-right:24px" nzBanner [nzMessage]="environment.platform_warning" />
          }
          <nz-badge
            [nzStatus]="(backendStatus$ | async) ?? 'error'"
            [nzText]="'BASIC.state' | translate"
            nz-popover
            [nzPopoverContent]="packageVersion"
            [nzPopoverTrigger]="'hover'" />
        </layout-default-header-item>
      }

      <layout-default-header-item direction="right">
        <header-search class="alain-default__search" [toggleChange]="searchToggleStatus" />
      </layout-default-header-item>
      @if (organizations$ | async; as organizations) {
        <layout-default-header-item direction="right">
          <div class="alain-default__nav-item d-flex align-items-center px-sm headerbar">
            <i nz-icon nzType="bank"></i>
            <nz-select
              class="org-select"
              nzPlaceHolder="'Select Organization'"
              [(ngModel)]="selectedOrganization"
              (ngModelChange)="switchOrganization(selectedOrganization || null)"
              [nzShowSearch]="true"
              [compareWith]="compareFn"
              [nzOptionOverflowSize]="20">
              @for (o of organizations; track o.identifier; let i = $index) {
                <nz-option [nzValue]="o" [nzLabel]="o.name!">
                  <span [ngClass]="{ 'font-weight-bold': o.identifier === selectedOrganization?.identifier }">
                    {{ o.name }}
                  </span>
                </nz-option>
              }
            </nz-select>
          </div>
        </layout-default-header-item>
      }
      @if (settings.scope(); as scope) {
        <layout-default-header-item direction="right">
          <div
            [hidden]="!showScopeSelector"
            nz-dropdown
            class="alain-default__nav-item d-flex align-items-center px-sm headerbar"
            [nzDropdownMenu]="menu"
            [nzDisabled]="availableScopes.length < 2">
            <i style="margin-right: 6px" nz-icon nzType="copy" nzTheme="outline"></i>
            <span>
              {{ scope | translateScope | translate }}
              @if (availableScopes.length > 1) {
                <i nz-icon nzType="down"></i>
              }
            </span>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                @for (s of availableScopes; track s) {
                  <li [nzSelected]="s === scope" nz-menu-item (click)="scopeChange(s)">
                    <span [ngClass]="{ 'font-weight-bold': s === scope }">{{ s | translateScope | translate }}</span>
                  </li>
                }
              </ul>
            </nz-dropdown-menu>
          </div>
        </layout-default-header-item>
      }
      <layout-default-header-item direction="right">
        <header-notify />
      </layout-default-header-item>
      <layout-default-header-item direction="right">
        <header-user />
      </layout-default-header-item>
      <ng-template #nav>
        <layout-navigation class="d-block py-lg" />
      </ng-template>
      <ng-template #contentTpl>
        <router-outlet />
      </ng-template>
    </layout-default>
  `,
  styles: [
    `
      :host ::ng-deep .ant-badge-status-text {
        color: white;
      }
      [hidden] {
        display: none !important;
      }
      :host ::ng-deep .org-select .ant-select-selector {
        min-width: 160px;
        background-color: transparent !important;
        color: white;
        border: none !important;
        box-shadow: none !important;
        padding-top: 1px;
        text-align: left;
      }
      :host ::ng-deep .org-select .ant-select-selection-placeholder {
        color: white;
      }
      :host ::ng-deep .org-select .ant-select-arrow {
        color: white;
      }
      :host ::ng-deep .org-select .ant-badge-status-text {
        color: white;
      }
    `
  ],
  imports: [
    LayoutDefaultModule,
    NzSpinModule,
    NzIconModule,
    NzButtonModule,
    NzAlertModule,
    NzBadgeModule,
    NzPopoverModule,
    HeaderSearchComponent,
    NzSelectModule,
    FormsModule,
    NgClass,
    NzDropDownModule,
    NzMenuModule,
    HeaderUserComponent,
    LayoutNavComponent,
    RouterOutlet,
    AsyncPipe,
    TranslateModule,
    TranslateScopePipe,
    NoticeIconModule,
    HeaderNotifyComponent
  ]
})
export class LayoutBasicComponent implements OnInit {
  private store = inject(Store);
  settings = inject(SettingsStore);

  // from store(s)
  private scope$ = toObservable(this.settings.scope);
  organizations$ = this.store
    .select(selectManyOrganizations)
    // eslint-disable-next-line @ngrx/avoid-mapping-selectors
    .pipe(map(orgs => orgs.toSorted((a, b) => (a?.name || '').localeCompare(b?.name || ''))));

  options: LayoutDefaultOptions = {
    logoExpanded: `./assets/logos/lector-ai-logo-white.svg`,
    logoCollapsed: `./assets/logos/lector-ai-icon-white.svg`
  };

  searchToggleStatus = false;
  showSettingDrawer = !environment.production;
  showFreshworkWidget: BehaviorSubject<boolean>;
  showScopeSelector = true;
  environment = environment;
  selectedOrganization?: Partial<Organization>;
  packageVersion: string;

  availableScopes: DocumentScope[] = [
    DocumentScope.Production,
    DocumentScope.Development,
    DocumentScope.Training,
    DocumentScope.Testing,
    DocumentScope.HealthCheck
  ];

  backendStatus$: Observable<'success' | 'error'>;
  docId: string;
  nzSuffixIcon: string | TemplateRef<NzSafeAny> = 'bank';

  constructor(
    public authService: AuthService,
    public helperService: HelperService,
    public acl: ACLService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private freshdeskService: FreshdeskService,
    private http: HttpClient,
    private startupService: StartupService
  ) {
    this.startupService.initialize();
    this.packageVersion = (packageVersion as any).version;

    this.authService.availableScopes$.subscribe(as => {
      this.availableScopes = as;
    });

    this.backendStatus$ = timer(50, 30000).pipe(
      switchMap(_ => {
        return this.http.get(`${environment.api.baseUrl}`, { responseType: 'text' }).pipe(
          map(res => {
            return 'success';
          }),
          catchError(err => {
            console.error(err);
            console.error('Caught error');
            return of('error');
          })
        );
      })
    ) as Observable<'success' | 'error'>;

    // Make sure the query params are always up to date
    combineLatest([
      this.scope$.pipe(filter(o => o != undefined)),
      this.authService.organization$.pipe(filter(o => o != undefined))
    ]).subscribe(([scope, organization]) => {
      this.activatedRoute.queryParams.pipe(take(1)).subscribe(currentQueryParams => {
        if (currentQueryParams['organization'] == organization?.identifier && currentQueryParams['scope'] == scope?.toString()) return;
        this.router.navigate([], {
          queryParams: {
            organization: organization?.identifier,
            scope: scope?.toString()
            // NOTE: do not set the id here; we need it to be preserved (by 'merge' down below)
          },
          queryParamsHandling: 'merge',
          relativeTo: this.activatedRoute
        });
      });
    });

    // Hide selectors in administration views
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let path = this.router.parseUrl(event.url).root.children[PRIMARY_OUTLET]?.segments[0].path;
        this.showScopeSelector = path === 'admin' ? false : true;
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(OrganizationUiActions.findAllTriggered({ filter: {}, q: '' }));

    combineLatest([this.authService.organization$, this.organizations$]).subscribe(([org, orgs]) => {
      const resolvedOrg = orgs.find(o => o?.identifier == org?.identifier);
      if (resolvedOrg) {
        this.freshdeskService.setFreshworkWidgetVisibility(resolvedOrg as any);
        this.selectedOrganization = resolvedOrg;
      }
    });
  }

  switchOrganization(o: Partial<Organization> | null) {
    if (o !== null) {
      const oldFilter = this.settings.documentFilter();
      this.settings.patch({ documentFilter: { ...oldFilter, document_class_identifier: undefined } });
      this.authService.switchOrganization(o);
    }
  }

  scopeChange(scope: DocumentScope) {
    this.settings.patch({ scope });
  }

  compareFn = (a: Organization, b: Organization) => a?.identifier == b?.identifier;
}
