import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { WorkflowApiActions, WorkflowUIActions } from './workflow.actions';
import { WorkflowService } from './workflow.service';

@Injectable()
export class WorkflowEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private workflowSrv: WorkflowService
  ) {}

  findAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WorkflowUIActions.findAllTriggered),
      switchMap(() => {
        return this.workflowSrv.load().pipe(
          map(({ data, errors }) => WorkflowApiActions.findAllSucceeded({ items: data, errors })),
          catchError(err => of(WorkflowApiActions.requestFailed({ errors: [err] })))
        );
      })
    );
  });
}
