import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { merge, of } from 'rxjs';
import { map, catchError, tap, switchMap, concatMap } from 'rxjs/operators';

import { PredictorApiActions, PredictorUiActions } from './predictor.actions';
import { PredictorService } from './predictor.service';

@Injectable()
export class PredictorEffects {
  findAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PredictorUiActions.findAllTriggered),
      switchMap(({ filter, q }) => {
        return this.predictorSrv.findMany({ ...filter, identifier: { iLike: `${q}` } }).pipe(
          map(({ data, errors }) =>
            data
              ? PredictorApiActions.findAllSucceeded({ items: data.predictors.edges.map(e => e.node), q })
              : PredictorApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(PredictorApiActions.requestFailed({ errors: [err] })))
        );
      })
    );
  });

  observe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PredictorUiActions.findAllTriggered),
      switchMap(({ filter, q }) => {
        const created$ = this.predictorSrv.observeCreated(filter).pipe(map(created => PredictorApiActions.created({ item: created })));
        const updated$ = this.predictorSrv.observeUpdated(filter).pipe(map(updated => PredictorApiActions.updatedOne({ item: updated })));
        const deleted$ = this.predictorSrv
          .observeDeleted(filter)
          .pipe(map(deleted => PredictorApiActions.deletedOne({ identifier: deleted.identifier! })));

        return merge(created$, updated$, deleted$);
      })
    );
  });

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PredictorUiActions.createTriggered),
      concatMap(p =>
        this.predictorSrv.create(p.input).pipe(
          map(({ data, errors }) =>
            data
              ? PredictorApiActions.createSucceeded({ identifier: data.createOnePredictor.identifier })
              : PredictorApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(PredictorApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  updateOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PredictorUiActions.updateOneTriggered),
      concatMap(p =>
        this.predictorSrv.update(p.identifier, p.update).pipe(
          map(({ data, errors }) =>
            data
              ? PredictorApiActions.updateOneSucceeded({ identifier: data.updateOnePredictor.identifier })
              : PredictorApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(PredictorApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  deleteOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PredictorUiActions.deleteOneTriggered),
      concatMap(p =>
        this.predictorSrv.delete(p.identifier).pipe(
          map(({ data, errors }) =>
            data
              ? PredictorApiActions.deleteOneSucceeded({ identifier: data.deleteOnePredictor.identifier })
              : PredictorApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(PredictorApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  onCreateOrUpdateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PredictorApiActions.createSucceeded, PredictorApiActions.updateOneSucceeded),
        tap(({ identifier }) => {
          this.msgSrv.info(this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.UPDATE.success'));
          this.router.navigate(['/admin/predictors', identifier]);
        })
      );
    },
    { dispatch: false }
  );

  onDeleteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PredictorApiActions.deleteOneSucceeded),
        tap(({ identifier }) => {
          this.msgSrv.info(this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.UPDATE.success'));
          this.router.navigate(['/admin/predictors']);
        })
      );
    },
    { dispatch: false }
  );

  onError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PredictorApiActions.requestFailed),
        tap(({ errors }) => {
          console.error(errors);
          this.msgSrv.error(this.translateSrv.instant('COMMON.ERROR.unexpected'));
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private predictorSrv: PredictorService,
    private msgSrv: NzMessageService,
    private translateSrv: TranslateService,
    private router: Router
  ) {}
}
