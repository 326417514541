import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'clear-storage-action',
  template: `
    <i nz-icon nzType="tool" class="mr-sm"></i>
    {{ 'BASIC.USER.deleteLocalStorage' | translate }}
  `,
  host: {
    '[class.flex-1]': 'true'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzIconModule, NzButtonModule, TranslateModule]
})
export class ClearStorageActionComponent {
  constructor(
    private modalSrv: NzModalService,
    private translate: TranslateService
  ) {}

  @HostListener('click')
  _click(): void {
    this.modalSrv.confirm({
      nzTitle: this.translate.instant('BASIC.USER.deleteLocalStorageModalText'),
      nzOnOk: () => {
        localStorage.clear();
        window.location.replace('/');
      }
    });
  }
}
