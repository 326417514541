import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApolloCacheService {
  resetInProgress$ = new BehaviorSubject<boolean>(false);
  constructor(private apollo: Apollo.Apollo) {}

  async clearStorage() {
    this.resetInProgress$.next(true);
    // Clear cache
    await this.apollo.use('frontend').client.clearStore();
    this.resetInProgress$.next(false);
  }

  private async debug() {
    console.log('Cache contents:');
    const cacheContents = this.apollo.use('frontend').client.cache.extract();
    console.log(cacheContents);
  }
}
