import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { signalStore, withState, withMethods, patchState, withHooks, getState } from '@ngrx/signals';
import { interval } from 'rxjs';

import { CacheMetadata } from './cache.model';

export const settingsFeatureKey = 'cache';
const LOCALSTORAGE_KEY = 'lector:cache'; // never change this value

const GC_INTERVAL = 2 * 60 * 1000; // trigger garbage collection every 2 minutes
const itemFromStorage = localStorage.getItem(LOCALSTORAGE_KEY);
const fromCache: Partial<CacheMetadata> = itemFromStorage ? JSON.parse(itemFromStorage) : {};

export const initialState: CacheMetadata = {
  lastCleanUp: fromCache.lastCleanUp ?? Date.now(),
  nextExpiration: fromCache.nextExpiration ?? null,
  meta: fromCache.meta ?? []
};

export const CachedStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(store => ({
    has(key: string): boolean {
      return localStorage.getItem(`${LOCALSTORAGE_KEY}:${key}`) !== null;
    },
    get<T>(key: string): T | null {
      const raw = localStorage.getItem(`${LOCALSTORAGE_KEY}:${key}`);
      if (raw === null) {
        return null;
      }
      try {
        return JSON.parse(raw as string) as T;
      } catch {
        return raw as T;
      }
    },
    store(key: string, value: any, expirationMs: number): void {
      const storageKey = `${LOCALSTORAGE_KEY}:${key}`;
      const exp = Date.now() + expirationMs;

      // only store meta in state
      patchState(store, settings => ({
        ...settings,
        meta: [...settings.meta.filter(meta => meta.key !== key), { key, expiration: exp }].toSorted((a, b) => a.expiration - b.expiration),
        nextExpiration: Math.min(exp, settings.nextExpiration ?? exp)
      }));

      // store value only in localStorage
      try {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(getState(store)));
        localStorage.setItem(storageKey, JSON.stringify(value));
      } catch (error) {
        if ((<Error>error).name === 'QuotaExceededError' || (<DOMException>error).code === DOMException.QUOTA_EXCEEDED_ERR) {
          console.warn('Cache quota exceeded, force to clear cache');
          this.clear();
          localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(getState(store)));
          localStorage.setItem(storageKey, JSON.stringify(value));
        } else {
          console.error(error);
        }
      }
    },
    remove(key: string): void {
      const storageKey = `${LOCALSTORAGE_KEY}:${key}`;

      // only store meta in state
      patchState(store, settings => ({
        ...settings,
        meta: settings.meta.filter(item => item.key !== key),
        nextExpiration: settings.meta.length ? Math.min(...settings.meta.map(item => item.expiration)) : null
      }));

      // store value only in localStorage
      localStorage.removeItem(storageKey);
    },
    clear(): void {
      patchState(store, () => ({
        meta: [],
        nextExpiration: null,
        lastCleanUp: Date.now()
      }));

      for (const key in localStorage) {
        if (key !== LOCALSTORAGE_KEY && key.startsWith(LOCALSTORAGE_KEY)) {
          localStorage.removeItem(key);
        }
      }
    },
    cleanUp(): void {
      patchState(store, settings => {
        const now = Date.now();
        const meta = settings.meta;

        let deletionCount = 0;
        // delete all expired items form meta and localStorage
        if (now > (settings.nextExpiration ?? now)) {
          settings.meta.forEach((item, idx) => {
            if (item.expiration < now) {
              meta.splice(idx, 1);
              localStorage.removeItem(`${LOCALSTORAGE_KEY}:${item.key}`);
              deletionCount++;
            }
          });
        }

        console.log(`🚚 garbage collected: removed ${deletionCount} items from local storage`);

        return {
          meta,
          nextExpiration: meta.length ? Math.min(...meta.map(item => item.expiration)) : null,
          lastCleanUp: now
        };
      });
    }
  })),
  withHooks({
    onInit(store) {
      // cleanup every 5 minutes.
      interval(GC_INTERVAL)
        .pipe(takeUntilDestroyed())
        .subscribe(() => store.cleanUp());
    }
  })
);
