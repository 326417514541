import { inject, Injectable } from '@angular/core';

import {
  CountDocumentsGQL,
  Document,
  DocumentBasicGQL,
  DocumentFilter,
  DocumentGQL,
  LockOneDocumentGQL,
  UpdatedDocumentGQL
} from 'src/app/graphql/frontend-data-graphql';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private documentGQL = inject(DocumentGQL);
  private documentBasicGQL = inject(DocumentBasicGQL);

  private countDocumentsGQL = inject(CountDocumentsGQL);
  private lockOneDocumentGQL = inject(LockOneDocumentGQL);
  private updatedGQL = inject(UpdatedDocumentGQL);

  private authSrv = inject(AuthService);

  findOne(id: string, options: { basic?: boolean } = {}) {
    if (options.basic === true) {
      return this.documentBasicGQL.fetch({ id });
    } else {
      return this.documentGQL.fetch({ id });
    }
  }

  observeUpdated(filter: DocumentFilter) {
    return this.updatedGQL.subscribe({ filter });
  }

  lockOne(id: string) {
    return this.lockOneDocumentGQL.mutate({ document_id: id });
  }

  isBlockedByOthers(document: Document) {
    return document?.locked_until && document?.locked_by !== this.authSrv.user?.email && new Date(document?.locked_until) > new Date();
  }

  isJustBlockedByMe(document: Document) {
    return this.isBlockedByMe(document) && Date.now() - new Date(document?.locked_at ?? 0).getTime() < 2000; // 2 seconds
  }

  isBlockedByMe(document: Document) {
    return document?.locked_until && document?.locked_by === this.authSrv.user?.email && new Date(document?.locked_until) > new Date();
  }

  count(filter: DocumentFilter) {
    return this.countDocumentsGQL.fetch({ filter });
  }
}
