import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';

import {
  CreatedNotificationConfirmGQL,
  MarkAllAsReadGQL,
  NotificationConfirmFilter,
  NotificationConfirmGQL,
  NotificationConfirmSortFields,
  NotificationConfirmSubscriptionFilter,
  NotificationConfirmsGQL,
  SortDirection,
  UpdateOneNotificationConfirmGQL,
  UpdateOneNotificationConfirmInput,
  UpdatedOneNotificationConfirmGQL
} from 'src/app/graphql/frontend-data-graphql';

@Injectable({ providedIn: 'root' })
export class NotificationConfirmService {
  constructor(
    private notificationConfirmsGQL: NotificationConfirmsGQL,
    private notificationConfirmGQL: NotificationConfirmGQL,
    private updateGQL: UpdateOneNotificationConfirmGQL,
    private createdGQL: CreatedNotificationConfirmGQL,
    private updatedGQL: UpdatedOneNotificationConfirmGQL,
    private markAsReadGQL: MarkAllAsReadGQL
  ) {}

  findMany(filter: NotificationConfirmFilter = {}) {
    return this.notificationConfirmsGQL.fetch({
      filter,
      paging: { first: 50 },
      sorting: [
        { field: NotificationConfirmSortFields.Confirmed, direction: SortDirection.Asc },
        { field: NotificationConfirmSortFields.Created, direction: SortDirection.Desc }
      ]
    });
  }

  findManyQuery(filter: NotificationConfirmFilter = {}) {
    return this.notificationConfirmsGQL.watch({
      filter,
      paging: { first: 50 },
      sorting: [
        { field: NotificationConfirmSortFields.Confirmed, direction: SortDirection.Asc },
        { field: NotificationConfirmSortFields.Created, direction: SortDirection.Desc }
      ]
    });
  }

  async findOne(id: string) {
    const { data, errors } = await firstValueFrom(this.notificationConfirmGQL.fetch({ id }));
    return { data, errors };
  }

  update(input: UpdateOneNotificationConfirmInput) {
    return this.updateGQL.mutate({ input });
  }

  markAsRead() {
    return this.markAsReadGQL.mutate();
  }

  observeCreated(filter: NotificationConfirmSubscriptionFilter) {
    return this.createdGQL.subscribe({ input: { filter: filter } }).pipe(map(({ data }) => data!.createdNotificationConfirm));
  }

  observeUpdated(filter: NotificationConfirmSubscriptionFilter) {
    return this.updatedGQL.subscribe({ input: { filter: filter } }).pipe(map(({ data }) => data!.updatedOneNotificationConfirm));
  }
}
