import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';
import { map, catchError, tap, switchMap, concatMap, exhaustMap } from 'rxjs/operators';

import { Organization } from 'src/app/graphql/frontend-data-graphql';

import { CredentialUiActions, OrganizationApiActions, OrganizationUiActions } from './organization.actions';
import { OrganizationService } from './organization.service';

@Injectable()
export class OrganizationEffects {
  findAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUiActions.findAllTriggered),
      switchMap(({ filter, q }) => {
        return this.organizationSrv.findMany({ ...filter, identifier: { iLike: `${q}` } }).pipe(
          map(({ data, errors }) =>
            data
              ? OrganizationApiActions.findAllSucceeded({ items: data.organizations as Organization[], q })
              : OrganizationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(OrganizationApiActions.requestFailed({ errors: [err] })))
        );
      })
    );
  });

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUiActions.createTriggered),
      concatMap(p =>
        this.organizationSrv.create(p.input).pipe(
          map(({ data, errors }) =>
            data
              ? OrganizationApiActions.createSucceeded({ identifier: data.createOneOrganization.identifier })
              : OrganizationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(OrganizationApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  updateOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUiActions.updateOneTriggered),
      concatMap(p =>
        this.organizationSrv.update(p.identifier, p.update).pipe(
          map(({ data, errors }) =>
            data
              ? OrganizationApiActions.updateOneSucceeded({ identifier: data.updateOneOrganization.identifier })
              : OrganizationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(OrganizationApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  deleteOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationUiActions.deleteOneTriggered),
      concatMap(p =>
        this.organizationSrv.delete(p.identifier).pipe(
          map(({ data, errors }) =>
            data
              ? OrganizationApiActions.deleteOneSucceeded({ identifier: data.deleteOneOrganization.identifier })
              : OrganizationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(OrganizationApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  createCredential$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CredentialUiActions.createTriggered),
      concatMap(p =>
        this.organizationSrv.createCredential(p.input).pipe(
          map(({ data, errors }) =>
            data
              ? OrganizationApiActions.updateOneSucceeded({ identifier: data.createOneCredential.identifier })
              : OrganizationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(OrganizationApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  updateCredential$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CredentialUiActions.updateOneTriggered),
      concatMap(p =>
        this.organizationSrv.updateCredential(p.update).pipe(
          map(({ data, errors }) =>
            data
              ? OrganizationApiActions.updateOneSucceeded({ identifier: data.updateOneCredential.identifier })
              : OrganizationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(OrganizationApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  deleteCredential$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CredentialUiActions.deleteOneTriggered),
      concatMap(p =>
        this.organizationSrv.deleteCredential(p.input).pipe(
          map(({ data, errors }) =>
            data
              ? OrganizationApiActions.updateOneSucceeded({ identifier: data.deleteOneCredential.identifier })
              : OrganizationApiActions.requestFailed({ errors: errors ?? [] })
          ),
          catchError(err => of(OrganizationApiActions.requestFailed({ errors: [err] })))
        )
      )
    );
  });

  onCreateOrUpdateSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationApiActions.createSucceeded, OrganizationApiActions.updateOneSucceeded),
      tap(({ identifier }) => {
        this.msgSrv.info(this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.UPDATE.success'));

        const url = this.router.url.split('?').at(0) ?? '';
        const postIndentifierPath = url.split(`/${identifier}/`);

        this.router.navigate(['/admin/organizations/edit', identifier, postIndentifierPath[1]].filter(Boolean));
      }),
      exhaustMap(() => of(OrganizationUiActions.findAllTriggered({ filter: {}, q: '' })))
    );
  });

  onDeleteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationApiActions.deleteOneSucceeded),
      tap(({ identifier }) => {
        this.msgSrv.info(this.translateSrv.instant('ADMIN.DOCUMENT-CLASSES.DOCUMENT-CLASS-ACTION.UPDATE.success'));
        this.router.navigate(['/admin/organizations']);
      }),
      exhaustMap(() => of(OrganizationUiActions.findAllTriggered({ filter: {}, q: '' })))
    );
  });

  onError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrganizationApiActions.requestFailed),
        tap(({ errors }) => {
          console.error(errors);
          this.msgSrv.error(this.translateSrv.instant('COMMON.ERROR.unexpected'));
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private organizationSrv: OrganizationService,
    private msgSrv: NzMessageService,
    private translateSrv: TranslateService,
    private router: Router
  ) {}
}
