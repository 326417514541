{
  "name": "lector-frontend",
  "description": "The lector.ai web frontend.",
  "version": "15.0.18",
  "scripts": {
    "ng": "ng",
    "start": "ng s -o --port 4200",
    "debug": "ng s -o --port 4200 --host=127.0.0.1",
    "build": "npm run ng-high-memory build --localize",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "ng-high-memory": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng",
    "hmr": "ng s -o --hmr --verbose",
    "analyze": "npm run ng-high-memory build -- --source-map",
    "analyze:view": "source-map-explorer dist/**/*.js",
    "test-coverage": "ng test --code-coverage --watch=false",
    "color-less": "ng-alain-plugin-theme -t=colorLess",
    "theme": "ng-alain-plugin-theme -t=themeCss",
    "icon": "ng g ng-alain:plugin icon",
    "lint": "npm run lint:ts && npm run lint:style",
    "lint:ts": "ng lint --cache",
    "lint:style": "npx stylelint \"src/**/*.less\"",
    "lint-fix": "npm run lint:ts -- --fix && npm run lint:style -- --fix",
    "codegen-frontend-data": "NODE_OPTIONS='--dns-result-order=ipv4first' DOTENV_CONFIG_PATH=.env graphql-codegen -r dotenv/config --config src/app/graphql/codegen-frontend-data.yml",
    "codegen-history": "NODE_OPTIONS='--dns-result-order=ipv4first' DOTENV_CONFIG_PATH=.env graphql-codegen -r dotenv/config --config src/app/graphql/codegen-history.yml",
    "storybook": "ng run frontend:storybook",
    "build-storybook": "ng run frontend:build-storybook"
  },
  "private": true,
  "engines": {
    "npm": "^10.2.4",
    "node": "^20.11.1"
  },
  "dependencies": {
    "@angular/animations": "^19.2.0",
    "@angular/cdk": "^19.2.1",
    "@angular/common": "^19.2.0",
    "@angular/compiler": "^19.2.0",
    "@angular/core": "^19.2.0",
    "@angular/forms": "^19.2.0",
    "@angular/platform-browser": "^19.2.0",
    "@angular/platform-browser-dynamic": "^19.2.0",
    "@angular/router": "^19.2.0",
    "@angular/service-worker": "^19.2.0",
    "@delon/abc": "^19.0.1",
    "@delon/acl": "^19.0.1",
    "@delon/auth": "^19.0.1",
    "@delon/theme": "^19.0.1",
    "@delon/util": "^19.0.1",
    "@fontsource/fira-mono": "^5.1.1",
    "@graphql-tools/wrap": "^10.0.29",
    "@nestjs-query/core": "^0.30.0",
    "@ngrx/effects": "^19.0.1",
    "@ngrx/entity": "^19.0.1",
    "@ngrx/operators": "^19.0.1",
    "@ngrx/router-store": "^19.0.1",
    "@ngrx/signals": "^19.0.1",
    "@ngrx/store": "^19.0.1",
    "@ngrx/store-devtools": "^19.0.1",
    "@ngx-formly/core": "^6.3.12",
    "@ngx-formly/ng-zorro-antd": "^6.3.12",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@popperjs/core": "^2.11.8",
    "apollo-angular": "^8.0.2",
    "apollo-link-scalars": "^4.0.3",
    "apollo-link-ws": "^1.0.20",
    "d3-drag": "^3.0.0",
    "d3-selection": "^3.0.0",
    "d3-shape": "^3.2.0",
    "d3-transition": "^3.0.1",
    "d3-zoom": "^3.0.0",
    "dagre": "^0.8.5",
    "dagre-compound": "^0.0.13",
    "date-fns": "^4.1.0",
    "echarts": "^5.2.2",
    "fabric": "5.3.0-browser",
    "file-saver": "^2.0.5",
    "graphql": "^16.10.0",
    "graphql-scalars": "^1.24.1",
    "graphql-ws": "^5.16.2",
    "ibantools": "^4.5.1",
    "jwt-decode": "^4.0.0",
    "lodash": "^4.17.21",
    "monaco-editor": "^0.33.0",
    "ng-alain": "^19.0.1",
    "ng-lazyload-image": "^9.1.3",
    "ng-zorro-antd": "^19.0.2",
    "ngx-echarts": "^19.0.0",
    "ngx-json-viewer": "^3.2.1",
    "ngx-markdown": "^19.1.0",
    "rxjs": "~7.8.0",
    "screenfull": "^6.0.2",
    "tesseract.js": "^5.1.1",
    "ts-node": "^10.9.1",
    "ts-pattern": "^5.6.0",
    "tslib": "^2.8.1",
    "uuid": "^11.0.5",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.2.0",
    "@angular/cli": "^19.2.0",
    "@angular/compiler-cli": "^19.2.0",
    "@angular/language-service": "^19.2.0",
    "@compodoc/compodoc": "^1.1.26",
    "@delon/testing": "^19.0.1",
    "@eslint/compat": "^1.2.7",
    "@graphql-codegen/cli": "^5.0.4",
    "@graphql-codegen/typescript": "^4.1.2",
    "@graphql-codegen/typescript-apollo-angular": "^4.0.0",
    "@graphql-codegen/typescript-operations": "^4.4.0",
    "@ngrx/eslint-plugin": "^19.0.1",
    "@ngrx/schematics": "^19.0.1",
    "@ngx-formly/schematics": "^6.3.12",
    "@storybook/addon-a11y": "^8.6.4",
    "@storybook/addon-docs": "^8.6.4",
    "@storybook/addon-essentials": "^8.6.4",
    "@storybook/addon-interactions": "^8.6.4",
    "@storybook/addon-links": "^8.6.4",
    "@storybook/angular": "^8.6.4",
    "@storybook/blocks": "^8.6.4",
    "@storybook/test": "^8.6.4",
    "@types/d3": "^7.4.3",
    "@types/dompurify": "^3.2.0",
    "@types/fabric": "^5.3.7",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.15",
    "@types/node": "~20.16.5",
    "@types/offscreencanvas": "^2019.7.3",
    "@types/uuid": "^10.0.0",
    "angular-eslint": "^19.1.0",
    "eslint": "^9.21.0",
    "eslint-config-prettier": "~10.0.2",
    "eslint-import-resolver-typescript": "^3.8.3",
    "eslint-plugin-import": "~2.31.0",
    "eslint-plugin-prettier": "~5.2.3",
    "eslint-plugin-sonarjs": "^3.0.2",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-alain-plugin-theme": "^18.0.0",
    "prettier": "^3.4.2",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "source-map-explorer": "^2.5.3",
    "storybook": "^8.6.4",
    "stylelint": "^16.14.1",
    "stylelint-config-rational-order": "^0.1.2",
    "stylelint-config-standard": "^37.0.0",
    "stylelint-declaration-block-no-ignored-properties": "^2.8.0",
    "stylelint-order": "^6.0.4",
    "typescript": "5.7.3",
    "typescript-eslint": "^8.25.0"
  },
  "lint-staged": {
    "(src)/**/*.{html,ts}": [
      "eslint --fix"
    ],
    "(src)/**/*.less": [
      "npm run lint:style"
    ]
  }
}
