import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cssVar',
  standalone: true
  // should to be `pure: false` because CSS variables can change dynamically
  // pure: false
})
export class CssVarPipe implements PipeTransform {
  transform(variableName: string, fallback?: string): string {
    // Add '--' prefix if not provided
    const name = variableName.startsWith('--') ? variableName : `--${variableName}`;

    // Get the computed value
    const value = getComputedStyle(document.documentElement).getPropertyValue(name).trim();

    if (!value) {
      return fallback || '';
    }

    return value;
  }
}
